import * as i0 from '@angular/core';
import { Injectable, SecurityContext, Component, Input, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import * as i2 from '@angular/platform-browser';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';

/** Notification Class */
const _c0 = a0 => ({
  pointer: a0
});
function NgxNotifierComponent_div_0_div_2_p_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "p", 10);
  }
  if (rf & 2) {
    const notification_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("innerHTML", notification_r4.message, i0.ɵɵsanitizeHtml);
  }
}
function NgxNotifierComponent_div_0_div_2_p_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const notification_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(notification_r4.message);
  }
}
function NgxNotifierComponent_div_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function NgxNotifierComponent_div_0_div_2_Template_div_click_0_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onToastClick(i_r2));
    })("keyup", function NgxNotifierComponent_div_0_div_2_Template_div_keyup_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onKeyUp());
    });
    i0.ɵɵelementStart(1, "button", 5);
    i0.ɵɵlistener("click", function NgxNotifierComponent_div_0_div_2_Template_button_click_1_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.removeNotification(i_r2));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(2, "svg", 6);
    i0.ɵɵelement(3, "path", 7);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(4, NgxNotifierComponent_div_0_div_2_p_4_Template, 1, 1, "p", 8)(5, NgxNotifierComponent_div_0_div_2_p_5_Template, 2, 1, "p", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const notification_r4 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", "alert-" + notification_r4.style)("@animateToasts", undefined);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r2.allowHTML);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.allowHTML);
  }
}
function NgxNotifierComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, NgxNotifierComponent_div_0_div_2_Template, 6, 4, "div", 3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r2.className)("@animateToasts", undefined)("@.disabled", ctx_r2.disableAnimations);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c0, ctx_r2.dismissOnClick));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.notifications);
  }
}
class Notification {
  /**
   * Constructor For Notification
   *
   * @param message message to be shown
   * @param style bootstrap background colors
   * @param duration timeout for notification
   */
  constructor(message, style, duration) {
    this.id = `_${Math.random().toString(36).substring(2, 11)}`; // generate a random number
    this.message = message;
    this.style = style || 'info';
    this.duration = duration;
  }
}

/** Notification Service, recieves notifications from user and interacts with components */
class NgxNotifierService {
  constructor() {
    /** notification which can be subscribed on new messages */
    this.notification = new Subject();
    /** clear all toast notifications */
    this.clearToasts = new Subject();
    /** clear last toast notification */
    this.clearLastToast = new Subject();
  }
  /** pushes a new notification */
  createToast(message, style, duration) {
    this.notification.next(new Notification(message, style, duration));
  }
  /** clear all toast notifications */
  clear() {
    this.clearToasts.next();
  }
  /** clear last toast notification */
  clearLast() {
    this.clearLastToast.next();
  }
  static {
    this.ɵfac = function NgxNotifierService_Factory(t) {
      return new (t || NgxNotifierService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxNotifierService,
      factory: NgxNotifierService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxNotifierService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Notifier compoent, which holds all the notifications can be accessed via `ngx-notifier` selector
 */
class NgxNotifierComponent {
  /**
   * NgxNotifierComponent Constructor
   *
   * @param ngxNotifierService subscribe to get values form notifier service
   */
  constructor(ngxNotifierService, domSanitizer) {
    this.ngxNotifierService = ngxNotifierService;
    this.domSanitizer = domSanitizer;
    this.componentDestroyed$ = new Subject();
    /** whether to allow duplicate messages or not */
    this.allowDuplicates = true;
    /** allow HTML in notification */
    this.allowHTML = false;
    /** custom class to be attached */
    this.className = '';
    /** default duration for dismissing notifications (60s/1minute) */
    this.duration = 60000;
    /** weather to enable or disable animations */
    this.disableAnimations = false;
    /** click to dismiss a notification */
    this.dismissOnClick = false;
    /** whether to insert on top or at bottom */
    this.insertOnTop = true;
    /** Maximum number of notifications to keep */
    this.max = 5;
    /** array of notifications */
    this.notifications = [];
    this.ngxNotifierService.notification.pipe(takeUntil(this.componentDestroyed$)).subscribe(notification => {
      this.updateNotifications(notification);
    });
    this.ngxNotifierService.clearToasts.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.notifications = [];
    });
    this.ngxNotifierService.clearLastToast.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.clearLastToast();
    });
  }
  /**
   * updates notification into the array i.e., Which is the display
   *
   * @param notification notification element
   */
  updateNotifications(notification) {
    // checks whether the message is alrady present in notifications
    const index = this.notifications.map(e => e.message).indexOf(notification.message);
    if (!this.allowDuplicates) {
      if (index !== -1) {
        return;
      }
    }
    // save the last inserted Id
    this.lastInsertedNotificationId = notification.id;
    // sanitize html if enableHTML is set to true
    let sanitizedMessage = null;
    if (notification.message && this.allowHTML) {
      sanitizedMessage = this.domSanitizer.sanitize(SecurityContext.HTML, notification.message);
    }
    // set sanitized output to notification message
    notification.message = sanitizedMessage || notification.message;
    // insert notification in the first position of the array
    if (this.insertOnTop) {
      this.notifications.unshift(notification);
    } else {
      this.notifications.push(notification);
    }
    /**
     * remove the last inserted element if max has
     * pop or shift based on `insertOnTop`
     */
    if (this.notifications.length > this.max) {
      if (this.insertOnTop) {
        this.notifications.pop();
      } else {
        this.notifications.shift();
      }
    }
    // clear notification in given time
    setTimeout(() => {
      this.notifications.splice(index, 1);
    }, notification.duration || this.duration);
  }
  /**
   * remove the element from the array based on index
   *
   * @param index position of the element
   */
  removeNotification(index) {
    if (index !== undefined || index !== null) {
      this.notifications.splice(index, 1);
    }
  }
  /**
   * click event when toast is cleared
   *
   * @param index position of the element
   */
  onToastClick(index) {
    if (this.dismissOnClick) {
      this.removeNotification(index);
    }
  }
  // dummy keyup handler
  onKeyUp() {
    // do nothing
  }
  /** clear last inserted toast notification */
  clearLastToast() {
    const index = this.notifications.map(e => e.id).indexOf(this.lastInsertedNotificationId);
    if (this.notifications.length !== 0 && index !== -1) {
      this.notifications.splice(index, 1);
    }
  }
  /** stop subscription when component is destroyed */
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
  static {
    this.ɵfac = function NgxNotifierComponent_Factory(t) {
      return new (t || NgxNotifierComponent)(i0.ɵɵdirectiveInject(NgxNotifierService), i0.ɵɵdirectiveInject(i2.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxNotifierComponent,
      selectors: [["ngx-notifier"]],
      inputs: {
        allowDuplicates: "allowDuplicates",
        allowHTML: "allowHTML",
        className: "className",
        duration: "duration",
        disableAnimations: "disableAnimations",
        dismissOnClick: "dismissOnClick",
        insertOnTop: "insertOnTop",
        max: "max"
      },
      decls: 1,
      vars: 1,
      consts: [["class", "ngx-notifier", 3, "ngClass", 4, "ngIf"], [1, "ngx-notifier", 3, "ngClass"], [3, "ngClass"], ["class", "ngx-n-notification alert", "role", "alert", 3, "ngClass", "click", "keyup", 4, "ngFor", "ngForOf"], ["role", "alert", 1, "ngx-n-notification", "alert", 3, "click", "keyup", "ngClass"], ["type", "button", "aria-label", "Close", 1, "pointer", "close", 3, "click"], ["xmlns", "http://www.w3.org/2000/svg", "width", "12", "height", "12", "fill", "currentColor", "viewBox", "0 0 16 16", "aria-hidden", "true"], ["d", "M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"], [3, "innerHTML", 4, "ngIf"], [4, "ngIf"], [3, "innerHTML"]],
      template: function NgxNotifierComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, NgxNotifierComponent_div_0_Template, 3, 7, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", (ctx.notifications == null ? null : ctx.notifications.length) !== 0);
        }
      },
      dependencies: [i3.NgClass, i3.NgForOf, i3.NgIf],
      styles: ["*[_ngcontent-%COMP%], *[_ngcontent-%COMP%]:after, *[_ngcontent-%COMP%]:before{box-sizing:border-box}.ngx-notifier[_ngcontent-%COMP%]{position:fixed;min-width:25rem;max-width:25rem;right:1rem;bottom:1rem}.ngx-notifier[_ngcontent-%COMP%]   .pointer[_ngcontent-%COMP%]{cursor:pointer}.ngx-notifier[_ngcontent-%COMP%]   .ngx-n-notification[_ngcontent-%COMP%]{margin-top:.5rem;border-radius:4px;padding:.5rem}.ngx-notifier[_ngcontent-%COMP%]   .ngx-n-notification[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin:0}.ngx-notifier[_ngcontent-%COMP%]   .ngx-n-notification[_ngcontent-%COMP%]   .close[_ngcontent-%COMP%]:focus{outline:none}.ngx-notifier[_ngcontent-%COMP%]   .alert[_ngcontent-%COMP%]{position:relative;padding:1rem 3rem 1rem 1rem;margin-bottom:1rem;border:1px solid transparent;border-radius:.25rem}.ngx-notifier[_ngcontent-%COMP%]   .alert-success[_ngcontent-%COMP%]{color:#0f5132;background-color:#d1e7dd;border-color:#badbcc}.ngx-notifier[_ngcontent-%COMP%]   .alert-info[_ngcontent-%COMP%]{color:#055160;background-color:#cff4fc;border-color:#b6effb}.ngx-notifier[_ngcontent-%COMP%]   .alert-warning[_ngcontent-%COMP%]{color:#664d03;background-color:#fff3cd;border-color:#ffecb5}.ngx-notifier[_ngcontent-%COMP%]   .alert-danger[_ngcontent-%COMP%]{color:#842029;background-color:#f8d7da;border-color:#f5c2c7}.ngx-notifier[_ngcontent-%COMP%]   .close[_ngcontent-%COMP%]{position:absolute;top:0;right:0;z-index:2;padding:1.25rem 1rem;width:1rem;height:1rem;background-color:transparent;border:none;box-sizing:content-box}@media only screen and (max-width: 420px){.ngx-notifier[_ngcontent-%COMP%]{min-width:20rem;max-width:20rem}}"],
      data: {
        animation: [trigger('animateToasts', [state('void', style({
          opacity: 0
        })), transition(':enter, :leave', [animate('0.3s ease')])])]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxNotifierComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-notifier',
      animations: [trigger('animateToasts', [state('void', style({
        opacity: 0
      })), transition(':enter, :leave', [animate('0.3s ease')])])],
      template: "<div\n  class=\"ngx-notifier\"\n  *ngIf=\"notifications?.length !== 0\"\n  [ngClass]=\"className\"\n  [@animateToasts]\n  [@.disabled]=\"disableAnimations\"\n>\n  <div [ngClass]=\"{ pointer: dismissOnClick }\">\n    <div\n      class=\"ngx-n-notification alert\"\n      role=\"alert\"\n      *ngFor=\"let notification of notifications; let i = index\"\n      [ngClass]=\"'alert-' + notification.style\"\n      (click)=\"onToastClick(i)\"\n      (keyup)=\"onKeyUp()\"\n      [@animateToasts]\n    >\n      <button type=\"button\" class=\"pointer close\" aria-label=\"Close\" (click)=\"removeNotification(i)\">\n        <svg\n          xmlns=\"http://www.w3.org/2000/svg\"\n          width=\"12\"\n          height=\"12\"\n          fill=\"currentColor\"\n          viewBox=\"0 0 16 16\"\n          aria-hidden=\"true\"\n        >\n          <path\n            d=\"M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z\"\n          />\n        </svg>\n      </button>\n      <p *ngIf=\"allowHTML\" [innerHTML]=\"notification.message\"></p>\n      <p *ngIf=\"!allowHTML\">{{ notification.message }}</p>\n    </div>\n  </div>\n</div>\n",
      styles: ["*,*:after,*:before{box-sizing:border-box}.ngx-notifier{position:fixed;min-width:25rem;max-width:25rem;right:1rem;bottom:1rem}.ngx-notifier .pointer{cursor:pointer}.ngx-notifier .ngx-n-notification{margin-top:.5rem;border-radius:4px;padding:.5rem}.ngx-notifier .ngx-n-notification p{margin:0}.ngx-notifier .ngx-n-notification .close:focus{outline:none}.ngx-notifier .alert{position:relative;padding:1rem 3rem 1rem 1rem;margin-bottom:1rem;border:1px solid transparent;border-radius:.25rem}.ngx-notifier .alert-success{color:#0f5132;background-color:#d1e7dd;border-color:#badbcc}.ngx-notifier .alert-info{color:#055160;background-color:#cff4fc;border-color:#b6effb}.ngx-notifier .alert-warning{color:#664d03;background-color:#fff3cd;border-color:#ffecb5}.ngx-notifier .alert-danger{color:#842029;background-color:#f8d7da;border-color:#f5c2c7}.ngx-notifier .close{position:absolute;top:0;right:0;z-index:2;padding:1.25rem 1rem;width:1rem;height:1rem;background-color:transparent;border:none;box-sizing:content-box}@media only screen and (max-width: 420px){.ngx-notifier{min-width:20rem;max-width:20rem}}\n"]
    }]
  }], () => [{
    type: NgxNotifierService
  }, {
    type: i2.DomSanitizer
  }], {
    allowDuplicates: [{
      type: Input
    }],
    allowHTML: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    duration: [{
      type: Input
    }],
    disableAnimations: [{
      type: Input
    }],
    dismissOnClick: [{
      type: Input
    }],
    insertOnTop: [{
      type: Input
    }],
    max: [{
      type: Input
    }]
  });
})();
class NgxNotifierModule {
  static {
    this.ɵfac = function NgxNotifierModule_Factory(t) {
      return new (t || NgxNotifierModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxNotifierModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NgxNotifierService],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxNotifierModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [NgxNotifierComponent],
      providers: [NgxNotifierService],
      exports: [NgxNotifierComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-notifier
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxNotifierComponent, NgxNotifierModule, NgxNotifierService };
